.contact_modal_card_boarder__holder{
  position: absolute;
  top: 0%;
  left: 0%;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.x-out-holder{
  color: white;
  cursor: pointer;
  
}
.x-out-holder-out{
  color: white;
  cursor: pointer;
  animation-name: spin;
animation-duration: 1s;
}
@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
.card {
  width: 500px;
  height: 500px;
  background: #07182E;
  position: relative;
  display: flex;
  place-content: center;
  place-items: center;
  overflow: hidden;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  opacity: 1;
  /* justify-content: space-around; */
animation-name: growIn;
animation-duration: 1s;
}

.card-shrink {
  width: 0px;
  height: 0px;
  background: #07182E;
  position: relative;
  display: flex;
  place-content: center;
  place-items: center;
  overflow: hidden;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  /* justify-content: space-around; */
animation-name: shrinkOut;
animation-duration: 1s;
}

@keyframes shrinkOut {
  from {height:500px; width:500px; opacity:1}
  to {height:0; width:0; opacity: 0;}
}

@keyframes growIn {
  from {height:0; width:0}
  to {height:500px; width:500px}
}


.card::before {
  content: '';
  position: absolute;
  width: 100px;
  background-image: linear-gradient(180deg, rgb(0, 183, 255), rgb(255, 48, 255));
  height: 130%;
  animation: rotBGimg 3s linear infinite;
  transition: all 0.2s linear;
}

@keyframes rotBGimg {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card::after {
  content: '';
  position: absolute;
  background: #07182E;
  ;
  inset: 5px;
  border-radius: 15px;
}  
/* .card:hover:before {
  background-image: linear-gradient(180deg, rgb(81, 255, 0), purple);
  animation: rotBGimg 3.5s linear infinite;
} */


@media (max-width:600px) {

  .card {
    width: 80%;
    height: 500px;
    background: #07182E;
    position: relative;
    display: flex;
    place-content: center;
    place-items: center;
    overflow: hidden;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    opacity: 1;
    /* justify-content: space-around; */
  animation-name: growInMobile;
  animation-duration: 1s;
  }


  @keyframes growInMobile {
    from {height:0; width:0}
    to {height:500px; width:80%}
  }

  .card-shrink {
    width: 0px;
    height: 0px;
    background: #07182E;
    position: relative;
    display: flex;
    place-content: center;
    place-items: center;
    overflow: hidden;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    /* justify-content: space-around; */
  animation-name: shrinkOutMobile;
  animation-duration: 1s;
  }
  
  @keyframes shrinkOutMobile {
    from {height:500px; width:80%; opacity:1}
    to {height:0; width:0; opacity: 0;}
  }
}