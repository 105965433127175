.inverted-input-holder{
  z-index: 1;
  width: 100%;
  display: flex;
}

.input {
  margin: 30px;
  background: none;
  border: none;
  outline: none;
  width: 80%;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 9999px;
  box-shadow: inset 2px 5px 10px rgb(5, 5, 5);
  color: #fff;
  text-align: center;
}