
.contact-form-hide{
  display: none;
  height: 0;
  width: 0;
}
.contact-form-show{
  display: flex;
  z-index: 999;
}

.socials__holder{
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
}

@media (max-width:815px){
  .socials__holder{
    position: absolute;
    top: 15%;
    right: 0px;
    color: white;
  }
}