.about-text{
  padding: 0% 2% 0% 2%;
  color: white;
  z-index: 999;
  font-size: 15px;
  text-align: left;
}

@media (max-width: 600px){
  .about-text{
    padding: 0% 2% 0% 2%;
    color: white;
    z-index: 999;
    font-size: 12.5px;
    text-align: left;
  }
}