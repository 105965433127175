.hero-banner__container{
color:white;
}
.red-area{
  position: absolute;
  height: 80px;
  width: 300px;
  top: 27%;
  z-index: -1;
  animation-name: flashRed;
  animation-duration: 2.5s;
}
@keyframes flashRed {
  0%{background-color: black;}
  40%{background-color: black;}
  50%{background-color: black;}
  65%{background-color: red;}
  100%{background-color: black;}
}
.image-center-style{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 99vh;
  width: 99vw;
}
.hero-headshot{
  height: 100%;
  animation-name: imageGrow;
  animation-duration: 2.5s;
}

@keyframes imageGrow {
  0%{height: 0%;}
  10%{height: 50%;}
  20%{height: 50%; transform: skew(-.06turn, 18deg); }
  35%{height: 50%; transform: skew(0, 0); }
  50%{height:100%}
}

.button__holder {
  position: absolute;
  top: 10%;
  left: 10%;
  opacity: 0;
  transform: rotate(0deg); /* Initial rotation angle */
  animation: buttonHolderFadeIn 1s forwards; /* Added forwards to keep the final state */
  animation-delay: 1s;
}

@keyframes buttonHolderFadeIn {
  from {
    top: 0%;
    left: 0%;
    opacity: 0;
    transform: rotate(90deg); /* Initial rotation angle */
  }
  to {
    top: 10%;
    left: 10%;
    opacity: 1;
    transform: rotate(0deg); /* Final rotation angle */
  }
}

.button-holder-start {
  position: absolute;
  top: 10%;
  left: 10%;
  opacity: 0;
  transform: rotate(0deg); /* Initial rotation angle */
}




/*  */

.social-holder {
  position: absolute;
  top: 10%;
  right: 10%;
  opacity: 0;
  transform: rotate(0deg); /* Initial rotation angle */
  animation: socialHolderFadeIn 1s forwards; /* Added forwards to keep the final state */
  animation-delay: 1s;
}

@keyframes socialHolderFadeIn {
  from {
    top: 50%;
    right: 50%;
    opacity: 0;
    transform: rotate(90deg); /* Initial rotation angle */
  }
  to {
    top: 0%;
    right: 0%;
    opacity: 1;
    transform: rotate(0deg); /* Final rotation angle */
  }
}

.social-holder-start {
  position: absolute;
  top: 10%;
  left: 10%;
  opacity: 0;
  transform: rotate(0deg); /* Initial rotation angle */
}
.button__holder-mobile{
display: none;
}

@media (max-width: 815px){
  @keyframes imageGrow {
    from{height: 0%; width: 0%;}
    to{height:100%; width:100%}
  }
  .hero-banner__container{
    color:white;
    height: 100vh;
    }
  .button__holder{
    display: none;
  }
  .button__holder-mobile{
    display: block;
    position: absolute;
    top: 5%;
    right:3%;
  }
  .hero-headshot{
    /* width: 100vw; */
    object-fit: cover;
    width: 100%;
    /* overflow: hidden; */
  }
  
  .red-area{
    position: absolute;
    height: 80px;
    width: 200px;
    top: 27%;
    z-index: -1;
    animation-name: flashRed;
    animation-duration: 2.5s;
  }
  .image-center-style{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  @keyframes socialHolderFadeIn {
    from {
      top: 50%;
      right: 50%;
      opacity: 0;
      transform: rotate(90deg); /* Initial rotation angle */
    }
    to {
      top: 20%;
      right: 0%;
      opacity: 1;
      transform: rotate(0deg); /* Final rotation angle */
    }
  }
}